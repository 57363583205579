// Build information, automatically generated by `ng-info`
const build = {
    version: "21.0.0",
    timestamp: "Wed Oct 09 2024 09:49:43 GMT+0200 (Central European Summer Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "8601dc",
        fullHash: "8601dcf0574c016dfaaa6d20d524c526cf5cc886"
    }
};

export default build;