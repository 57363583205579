import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChartOrTemplateDto } from 'app/jobsites-management/column-visualization/models/template/chartOrTemplate.dto'
import { JobsiteTableDataValues } from '../../jobsites-management/jobsite-summary/jobsite-data-table/models/jobsite-table-data-values.model'
import { CastaurDto } from 'app/jobsites-management/jobsite-summary/models/castaur.dto'
import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { LocalizedDateService } from 'app/shared/utils/services/localized-date.service'
import { forkJoin, Observable, of, switchMap } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { FileModel } from '../../jobsites-management/column-visualization/models/file.model'
import { Group } from '../../jobsites-management/jobsite-summary/jobsite-data-table/models/group.model'
import { ExportDailyReportPayload } from '../../jobsites-management/jobsite-summary/models/export.model'
import { ColumnDescriptor } from '../../shared/utils/column-descriptor'
import { GoogleAnalyticsService } from './google-analytics.service'
import { TableFormatterService } from './tableformatter.service'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'
import {
  HFDeviationExportParams,
  HFVerticalityExportParams,
} from '../../jobsites-management/jobsite-summary/components/export-pdf-dialog/export-pdf-dialog.model'
import * as Sentry from '@sentry/angular'
import {
  DtoDaily,
  DtoDailyGroup,
  DtoDailyGroupLine,
  DtoDailyHeader,
  ExportHFSuffix,
  ExportSuffix,
} from '../models/export.model'
import { ProdEventSummaries } from '../../jobsites-management/jobsite-summary/models/prod-event.model'
import {
  isExportHFSuffix,
  isExportRKSuffix,
} from '../models/export.model.type.guard'
import { DateRange } from '../../shared/localized-date-time-picker/localized-date-range-picker.component'
import { isJobsiteTableDataValues } from '../../jobsites-management/jobsite-summary/jobsite-data-table/models/jobsite-table-data-values.model.type.guard'
import { VerticalityDataWithUnit } from '../../jobsites-management/jobsite-summary/components/verticality-data-csv-import-dialog/verticality-data-csv-import-dialog.model'

@Injectable()
export class ExportService {
  private baseUrl = 'export/export'

  constructor(
    private http: HttpClient,
    private tableFormatterService: TableFormatterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private localizedDateService: LocalizedDateService,
    private translate: TranslateService,
  ) {}

  getCsv(
    techniqueName: TechniqueNames,
    jobsiteId: string,
    columnId: string,
    jobsiteName: string,
    columnName: string,
  ): Observable<FileModel | null> {
    return this.getFile(
      `${jobsiteName}-${columnName}.zip`,
      `${this.baseUrl}/csv/${techniqueName}/${jobsiteId}/${columnId}`,
      false,
      'application/zip;charset=utf-8,',
      undefined,
      undefined,
      (err: HttpErrorResponse) => {
        this.displayError(
          err.status === 404 ? 'ALERT.ERROR' : 'GENERAL.UNEXPECTED_ERROR.TITLE',
          err.status === 404
            ? 'ALERT.FILE_NOT_FOUND'
            : 'GENERAL.UNEXPECTED_ERROR.DESCRIPTION',
        )
        return of(null)
      },
    )
  }

  getDataVerticalityCsv(
    techniqueName: TechniqueNames,
    columnId: string,
    jobsiteName: string,
    columnName: string,
  ): Observable<FileModel | null> {
    return this.getFile(
      `${jobsiteName}-${columnName}-verticality-data.csv`,
      `${this.baseUrl}/${techniqueName}/verticality/csv/${columnId}`,
      false,
      'application/zip;charset=utf-8,',
      undefined,
      undefined,
      (err: HttpErrorResponse) => {
        this.displayError(
          err.status === 404 ? 'ALERT.ERROR' : 'GENERAL.UNEXPECTED_ERROR.TITLE',
          err.status === 404
            ? 'ALERT.PILE_NO_VERTICALITY_DATA'
            : 'GENERAL.UNEXPECTED_ERROR.DESCRIPTION',
        )
        return of(null)
      },
    )
  }

  getMultipleCsv(
    techniqueName: TechniqueNames,
    jobsiteId: string,
    columnIds: string[],
  ): Observable<Blob | null> {
    return this.getBlob(
      `${this.baseUrl}/csv/${techniqueName}/${jobsiteId}`,
      true,
      'data:application/zip;charset=utf-8,',
      columnIds,
    )
  }

  getMultiplePdf(
    techniqueName: TechniqueNames,
    jobsiteId: string,
    columnIds: string[],
    params: {
      verticality?: HFVerticalityExportParams
      sma?: number
      deviation?: HFDeviationExportParams
    },
    suffix: ExportSuffix,
  ): Observable<HttpResponse<unknown>> {
    let requestParams: Record<string, string> = {}
    let url: string

    if (isExportHFSuffix(suffix)) {
      requestParams =
        suffix === 'deviation'
          ? this.getHfDeviationRequestParam(params.deviation)
          : this.getHfVerticalityRequestParam(params.verticality)
      url = `${this.baseUrl}/pdf/HF/${suffix}/${jobsiteId}`
    } else if (isExportRKSuffix(suffix)) {
      requestParams = this.getRkRequestParam(params.sma)
      url = `${this.baseUrl}/pdf/rk/${techniqueName}/${jobsiteId}`
    } else {
      url = `${this.baseUrl}/pdf/${techniqueName}/${jobsiteId}`
    }

    return this.http.post(url, columnIds, {
      params: requestParams,
      observe: 'response',
    })
  }

  getSinglePdf(
    techniqueName: TechniqueNames,
    jobsiteId: string,
    columnId: string,
    body: ChartOrTemplateDto | undefined,
    columnName: string,
    jobsiteName: string,
    params: {
      verticality?: HFVerticalityExportParams
      sma?: number
      deviation?: HFDeviationExportParams
    },
    suffix: ExportSuffix,
    selectedHeaderIds: string[],
  ): Observable<FileModel | null> {
    const fileName = `${jobsiteName}-${columnName}.pdf`
    let requestParams: Record<string, string> = {}
    let url: string
    let isPostRequest = true

    if (isExportHFSuffix(suffix)) {
      url = `${this.baseUrl}/pdf/HF/${suffix}/${jobsiteId}/${columnId}`
      requestParams =
        suffix === 'deviation'
          ? this.getHfDeviationRequestParam(params.deviation)
          : this.getHfVerticalityRequestParam(params.verticality)
      isPostRequest = false
    } else if (isExportRKSuffix(suffix)) {
      url = `${this.baseUrl}/pdf/rk/${techniqueName}/${jobsiteId}/${columnId}`
      requestParams = this.getRkRequestParam(params.sma)
    } else if (body != null) {
      url = `${
        this.baseUrl
      }/pdf/${techniqueName}/${jobsiteId}/${columnId}/${body.type.toLowerCase()}/${
        body.id
      }`
    } else {
      return of(null)
    }

    return this.getFile(
      fileName,
      url,
      isPostRequest,
      'application/pdf;charset=utf-8,',
      selectedHeaderIds,
      requestParams,
    )
  }

  getSinglePdfFromCsv(
    jobsiteId: string,
    columnId: string,
    body: VerticalityDataWithUnit,
    columnName: string,
    jobsiteName: string,
    params: {
      verticality?: HFVerticalityExportParams
      deviation?: HFDeviationExportParams
    },
    suffix: Extract<ExportHFSuffix, 'deviation' | 'verticality'>,
  ): Observable<FileModel | null> {
    const fileName = `${jobsiteName}-${columnName}.pdf`
    const requestParams =
      suffix === 'deviation'
        ? this.getHfDeviationRequestParam(params.deviation)
        : this.getHfVerticalityRequestParam(params.verticality)

    const url = `${this.baseUrl}/pdf/HF/${suffix}/${jobsiteId}/${columnId}/from-csv`

    return this.getFile(
      fileName,
      url,
      true,
      'application/pdf;charset=utf-8,',
      body,
      requestParams,
    )
  }

  getCastaurCsvZip(
    techniqueName: TechniqueNames,
    castaurInfos: CastaurDto,
  ): Observable<unknown> {
    return this.http.post(
      `${this.baseUrl}/castaur/${techniqueName}`,
      castaurInfos,
    )
  }

  getDailyRkReportPdf(
    techniqueName: TechniqueNames,
    jobsiteId: string,
    fileName: string,
    enableMap: boolean,
  ): Observable<FileModel | null> {
    return this.getFile(
      fileName,
      `${this.baseUrl}/pdf/rk/daily/${techniqueName}/${jobsiteId}`,
      true,
      'application/pdf;charset=utf-8,',
      {
        enableMap,
      },
    )
  }

  public getDailyReport(
    techniqueName: TechniqueNames,
    jobsiteId: string,
    name: string,
    dailyReportData: Array<Group | JobsiteTableDataValues>,
    columns: { [columnName: string]: ColumnDescriptor },
    units: Record<string, string>,
    payload: ExportDailyReportPayload,
    prodEventsSummaries: ProdEventSummaries | null,
    dateRange?: DateRange,
  ): Observable<FileModel | null> {
    this.googleAnalyticsService.event('GENERATE_DAILYREPORT', {
      technique: techniqueName,
    })

    const pileIds: string[] = dailyReportData
      .filter(
        (d: Group | JobsiteTableDataValues): d is JobsiteTableDataValues =>
          isJobsiteTableDataValues(d),
      )
      .filter((d: JobsiteTableDataValues) => !d.discarded)
      .map((d: JobsiteTableDataValues) => d.id)
    const groups: DtoDailyGroup[] = this.dailyReportDataToGroups(
      dailyReportData,
      prodEventsSummaries,
    )

    columns = { name: new ColumnDescriptor(-1, true), ...columns }
    const keys: string[] = Object.keys(columns)
      .filter(
        key =>
          key !== 'discarded' &&
          key !== 'links' &&
          key !== 'prodEvents' &&
          key !== 'cageProd' &&
          key !== 'concreteProd' &&
          columns[key].selected,
      )
      .sort((a, b) => columns[a].index - columns[b].index)

    return forkJoin(
      keys.map(
        (key: string): Observable<DtoDailyHeader> =>
          this.tableFormatterService
            .getHeaderTranslationForLanguage(key, units, payload.reportLanguage)
            .pipe(
              map(
                (translation: string): DtoDailyHeader => ({
                  key,
                  label: translation,
                }),
              ),
            ),
      ),
    ).pipe(
      map(
        (headers: DtoDailyHeader[]): DtoDaily => ({
          ...payload,
          headers: headers,
          groups: groups,
          pileIds: pileIds,
          startDate: dateRange?.startDate?.format('YYYY-MM-DD'),
          endDate: dateRange?.endDate?.format('YYYY-MM-DD'),
        }),
      ),
      switchMap(
        (body: DtoDaily): Observable<FileModel | null> =>
          this.getFile(
            `${name}.pdf`,
            `${this.baseUrl}/pdf/daily/${techniqueName}/${jobsiteId}`,
            true,
            'application/pdf;charset=utf-8,',
            body,
            undefined,
            (error: HttpErrorResponse) => {
              if (error.status === 404) {
                this.displayError(
                  'ALERT.EMPTY_PDF.TITLE',
                  'ALERT.EMPTY_PDF.TEXT',
                )
              } else {
                Sentry.captureException(error.message)
                this.displayError(
                  'GENERAL.UNEXPECTED_ERROR.TITLE',
                  'GENERAL.UNEXPECTED_ERROR.DESCRIPTION',
                )
              }
              return of(null)
            },
          ),
      ),
    )
  }

  private getFile(
    fileName: string,
    url: string,
    postRequest: boolean,
    blobType: string,
    body?: unknown,
    params?: Record<string, string | boolean>,
    handleError: (error: HttpErrorResponse) => Observable<null> = (
      _: HttpErrorResponse,
    ) => of(null),
  ): Observable<FileModel | null> {
    return this.getBlob(
      url,
      postRequest,
      blobType,
      body,
      params,
      handleError,
    ).pipe(
      map(res =>
        res
          ? {
              fileName,
              data: res,
            }
          : null,
      ),
    )
  }

  private getBlob(
    url: string,
    postRequest: boolean,
    blobType: string,
    body?: unknown,
    params?: Record<string, string | boolean>,
    handleError: (error: HttpErrorResponse) => Observable<null> = (
      _: HttpErrorResponse,
    ) => of(null),
  ): Observable<Blob | null> {
    const observable = postRequest
      ? this.http.post(url, body, {
          responseType: 'blob',
          observe: 'response',
          params,
        })
      : this.http.get(url, {
          responseType: 'blob',
          observe: 'response',
          params,
        })

    return observable.pipe(
      map(res =>
        res.body
          ? new Blob([res.body], {
              type: blobType,
            })
          : null,
      ),
      catchError((error: HttpErrorResponse) => handleError(error)),
    )
  }

  private getHfVerticalityRequestParam(
    params?: HFVerticalityExportParams,
  ): Record<string, string> {
    const requestParams: Record<string, string> = {}
    if (params?.stepValue != null && params?.stepUnit != null) {
      requestParams.stepValue = params.stepValue.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })
      requestParams.stepUnit = params.stepUnit
    }
    return requestParams
  }

  private getHfDeviationRequestParam(
    params?: HFDeviationExportParams,
  ): Record<string, string> {
    const requestParams: Record<string, string> = {}
    if (params?.fullToleranceCone != null) {
      requestParams.fullToleranceCone = String(params.fullToleranceCone)
    }
    return requestParams
  }

  private getRkRequestParam(sma: number | undefined): Record<string, string> {
    const requestParam: Record<string, string> = {}
    if (sma != null) {
      requestParam.sma = sma?.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })
    }
    return requestParam
  }

  private dailyReportDataToGroups(
    dailyReportData: Array<Group | JobsiteTableDataValues>,
    prodEventSummaries: ProdEventSummaries | null,
  ): DtoDailyGroup[] {
    return dailyReportData.reduce<DtoDailyGroup[]>(
      (acc: DtoDailyGroup[], val: Group | JobsiteTableDataValues) => {
        if (acc.length === 0 || val instanceof Group) {
          let groupValue: string | undefined = undefined
          const lines: DtoDailyGroupLine[] = []

          if (val instanceof Group) {
            groupValue = `${
              val.date
                ? this.localizedDateService.getFormattedDate(val.date)
                : 'N/A'
            } (${val.totalCounts})`
          } else {
            if (!val.discarded)
              lines.push(
                this.tableFormatterService.getNormalizedLine(
                  val,
                  prodEventSummaries,
                ),
              )
          }
          return [...acc, { value: groupValue, lines }]
        }
        const currentGroup: DtoDailyGroup = acc[acc.length - 1]
        if (!val.discarded) {
          currentGroup.lines = [
            ...currentGroup.lines,
            this.tableFormatterService.getNormalizedLine(
              val,
              prodEventSummaries,
            ),
          ]
        }
        return acc
      },
      [],
    )
  }

  private displayError(
    titleTranslateKey: string,
    textTranslateKey: string,
  ): void {
    Swal.fire({
      title: this.translate.instant(titleTranslateKey),
      text: this.translate.instant(textTranslateKey),
      icon: 'error',
      confirmButtonText: 'Ok',
    })
  }
}
